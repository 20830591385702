@import '@renderer-ui-library/mui/base/constants.module.scss';

.machines {
  padding: ($base * 4) ($base * 6) ($base * 2) ($base * 6);
  max-width: 80vw;
  max-height: 60vh;
  overflow-y: auto;

  .moreColumns {
    column-count: 5;
    column-gap: $base * 2;
  }
}
