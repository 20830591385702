@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1;
  overflow: hidden;
}

.media {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
}

.cover {
  object-fit: cover;
}

.video {
  width: 100%;
  height: 100%;
}

// https://stackoverflow.com/questions/24579785/force-iframe-youtube-video-to-center-fit-and-full-cover-the-screen-in-the-backgr
.videoCoverMode {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  @media (min-aspect-ratio: 16/9) {
    height: 56.25vw;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 177.78vh;
  }
}

.figCaption {
  height: 35px;
  padding: 4px;
  position: absolute;
  top: calc(100% - 30px);
  text-align: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: $textInvertedPrimary;
}
