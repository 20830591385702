@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';

.contactWrapper {
  padding-top: $base;
}

.content {
  padding: ($base * 3) ($base * 3) ($base * 2);
}

.imageWrapper {
  position: relative;
  overflow: hidden;
  width: $base * 8;
  height: $base * 8;
  border-radius: 50%;
  margin-right: $base * 1.5;
  border: solid 1px $outlinedBorder;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.contactInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2 * $base;
}

.contactInfoContainerDesktop {
  margin-top: $base;
}

.iconButton {
  transition: none !important;
  background-color: transparent !important;

  &:hover {
    background-color: transparent;
  }
}

.icon {
  margin-right: $base;
}

.iconContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.buttonGroup {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 2 * $base;

  div {
    display: flex;
  }

  div + div {
    margin-left: $base;
  }

  &.iconOnly {
    height: auto;
  }
}

.iconOnlyWrapper {
  display: none;

  @include mediaMin('md') {
    display: flex;
  }
}

.iconOnly {
  justify-content: flex-start;
  align-items: flex-start;
}

.iconOnlyButton {
  padding: 0 24px 0 0 !important;
  width: 100%;
}

.imageMobileWrapper {
  width: $base * 4;
  height: $base * 4;
  border: solid 1px white;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.desktopWrapper {
  position: relative;
}

.mobileWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 700;
  display: none;
}

.mobilButtonsOverlay {
  width: 100%;
  height: $base * 5;
}

.wrapper {
  position: fixed;
  bottom: $base * 3;
  right: $base * 3;
  z-index: $zIndex5;

  .hidden {
    pointer-events: none;
  }

  @include mediaMax('sm') {
    right: $base * 2;
    bottom: $base * 2;
  }

  .button {
    background-color: $textPrimary;
    color: white;
  }

  .label {
    white-space: nowrap;
    padding-left: $base * 0.5;

    @include mediaMax('md') {
      display: none;
    }
  }
}

.popover {
  margin-top: $base * -3;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
}

.slideIconOnly {
  position: absolute;
  height: 0;
  bottom: 30%;
}

.paper {
  background: $backgroundPaper !important;
}

.slideContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $base * 3;
  color: $primary;
  background-color: $secondary;
  height: $base * 5;
}

.clickable {
  cursor: pointer;
}

.clickableIconOnly {
  font-size: $subtitle1FontSize;
  margin: $base;
}

.phoneContent {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 9px 0;
}

.contacInfoMobileWrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: 2;
}

.hideContactInfoWrapper {
  visibility: hidden;
  transition: all $defaultTransition;
}

.phoneButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;
}

.phoneOutlinedIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px $border;
  border-radius: $base * 3;
}

.container {
  bottom: auto;
}

.contactHeadline {
  color: $primary;
}

.headlineWrapper {
  border-bottom: $base * 0.5 solid $petrol;
}

.caption {
  font-size: $subtitle2FontSize;
}

.body2 {
  font-size: $body2FontSize;
}

.contactWrapper .contactPersonName {
  font-weight: 500;
}
