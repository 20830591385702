@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.button.button {
  width: 100%;
  height: 100%;
  padding: $base * 0.5;
  border-radius: $base * 0.5;
  background: white;
}

.outlined {
  border: 1px solid $outlinedBorder;
}
