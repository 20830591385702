@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/breakpoints.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $border;
  border-radius: $base;
  margin: calc($base * 3) 0 0;
  padding: 0 calc($base * 2) calc($base * 2);
  height: calc(100% - calc($base * 6));
  gap: calc($base * 3);
}

.ctaWrapper {
  align-self: flex-end;
  padding: 0 calc($base * 3) calc($base * 3);

  @include mediaMin('md') {
    padding: calc($base * 3);
  }
}

.image {
  min-height: 200px;
}

.description {
  min-height: 100px;
  color: $primary;
}
