@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon {
  display: flex;
  font-size: $base * 10;
  margin-bottom: $base * 3;
}

.title {
  margin-bottom: $base;
}

.error {
  color: $error;
}

.message {
  margin-bottom: $base * 3;
  max-width: 400px;
}

.button {
  width: 100%;
  @include mediaMin('md') {
    width: unset;
  }
}
