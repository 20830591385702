@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.searchInputWrapper {
  display: flex;
  width: 100%;
  background: white;
  border-radius: 4px;
  padding: 2px;

  .searchInput {
    padding: 0 8px;
    width: 100%;
    background: none;
    border: none;
    font-family: inherit;
    font-size: $inputFontSize;
    line-height: $inputLineHeight;

    &:focus-visible {
      outline: none;
    }
  }

  .iconButton {
    border-radius: 4px;
    background: $secondary;
    color: $primary;

    &:hover {
      background: $secondaryDark;
    }
  }
}
