@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.link {
  display: inline-block;

  a {
    &:hover,
    &:focus,
    &:active {
      color: $secondary;
      text-shadow: 0 0 1px $secondary;
      outline: 0;
    }
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
