@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.formContainer {
  display: flex;
  flex-direction: column;
}

.subline {
  & p {
    font-size: $body1FontSizeMobile;

    @include mediaMin('md') {
      font-size: $body1FontSize;
    }
  }
}
