@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';

.mobileWrapper {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex8;
  pointer-events: none;
}

.mobileDetailsWrapper {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: $base * 1.5 $base;
  border-top: solid 1px $divider;

  > div {
    margin: 0px $base;
  }

  span {
    font-size: $base * 1.5;
  }

  .iconContainer {
    background: transparent;
    width: $base * 4.5;
    height: $base * 4.5;
    margin-right: $base * 0.5;
  }
}

.mobileWrapperOpen {
  pointer-events: all;
}

.desktopWrapper {
  display: block;
  position: fixed;
  right: $base * 3;
  bottom: $base * 3;
  z-index: $zIndex5;

  @include mediaMax('md') {
    right: $base * 2;
    bottom: $base * 2;
  }
}

.close {
  cursor: pointer;
}

.icon {
  font-size: $base * 3;
  margin-right: $base;
}

.popover {
  z-index: $zIndex8;
  border-radius: $base * 2 !important;
}

.wrapper {
  width: 100%;
  background: #fff;

  @include mediaMin('md') {
    width: 360px;
    padding: $base * 2 $base * 3 $base;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $base * 2;
}

.description {
  margin-bottom: $base * 2;
}

.button {
  border-radius: 26px !important;

  p {
    margin-left: $base * 0.5;
  }

  @include mediaMax('md') {
    padding: 0 !important;
    border-radius: 26px !important;
  }
}

.iconContainer {
  width: $base * 6.5;
  height: $base * 6.5;
  border-radius: 50%;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;

  @include mediaMin('md') {
    width: $base * 4.5;
    height: $base * 4.5;
  }
}

.salesManagerContactWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  justify-content: center;
  gap: $base * 2;

  > :only-child {
    grid-column: 1 / -1;
    justify-self: center;
  }

  @supports (grid-template-columns: subgrid) {
    grid-template-columns: repeat(2, minmax(0, max-content));
  }
}
