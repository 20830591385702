@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';

$overlayMaxHeight: 80vh;
$overlayMaxWidth: 100%;
$overlayBoderRadius: 20px 20px 0 0;

.shim {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $shim;
  opacity: 0;
  pointer-events: none;

  transition: opacity $defaultTransition;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  max-height: $overlayMaxHeight;
  background: white;
  border-radius: $overlayBoderRadius;
  transform: translateY($overlayMaxHeight);

  transition: transform $defaultTransition;
}

.fullHeight {
  height: 100%;
  max-height: 100vh;
  max-height: -webkit-fill-available; // iOS 100vh fix
  transform: translateY(100vh);
  border-radius: 0;
}

.left {
  transform: translateX(-$overlayMaxWidth);

  &.open {
    transform: translateX(0);
  }
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $base * 2;
  border-radius: $overlayBoderRadius;
  border-bottom: 1px solid $divider;
  min-height: $base * 7;
}

.titleText {
  padding: $base 0;
}

.back,
.close {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  font-size: 20px;
}

.back {
  left: $base * 2;
}

.close {
  right: $base * 2;
}

.content {
  padding: $base * 3 $base * 2;
  overflow-y: auto;
  z-index: $zIndex10;
  pointer-events: auto;
}

.open {
  transform: translateY(0);
}
