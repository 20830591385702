@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.social {
  margin-top: $base;
}

.socialIcon {
  display: inline-block;
  width: $base * 5;
  height: $base * 5;

  & + & {
    margin-left: $base;
  }

  @include mediaMin('lg') {
    width: $base * 6;
    height: $base * 6;
  }
}
