@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  font-size: $base * 2;
  display: flex;
  color: $actionActive;
}

.dark {
  color: #fff;
}

.icon {
  margin-top: 3px;
  margin-right: calc($base / 2);
}
