@import '@renderer-ui-library/mui/base/constants.module.scss';

.levelOneNavigationWrapper {
  .entry {
    & + & {
      margin-top: $base * 3;
    }
  }

  .brands {
    max-width: calc(100% + #{$base * 3});
    margin-top: $base * 2;
    margin-right: -$base * 3;
    overflow-x: scroll;
  }

  .brandsContent {
    display: inline-block;
    padding-right: $base;
  }

  .socialButtons {
    display: flex;
    justify-content: center;
    margin: $base * 2 auto;
  }
}
