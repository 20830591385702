@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.gridWrapper {
  position: relative;
}

.columnContent {
  text-align: center;

  @include mediaMin('md') {
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.image {
  display: flex;
  flex: 1;
  max-width: 100%;
  margin-bottom: $base * 2;

  @include mediaMin('md') {
    margin-right: $base * 2;
    margin-bottom: 0;
    max-width: calc(#{$contentMaxWidth} / 4);
  }
}

.stretched {
  @include mediaMin('md') {
    .columnContent {
      max-width: calc(#{$contentMaxWidth} / 3);
    }
  }
}
