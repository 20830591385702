@import '@renderer-ui-library/mui/base/constants.module.scss';

.list {
  list-style: none;
  margin-top: $base * 4;
  padding: 0 0 $base * 5 0;
}

.item {
  margin-bottom: $base * 2;
}

.allButton {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}

$TRIPLE_BASE: $base * 3;

.inputWrapper {
  background: white;
  padding: $TRIPLE_BASE;
  position: sticky;
  top: -$TRIPLE_BASE;
  margin: 0 (-$TRIPLE_BASE) 0 (-$TRIPLE_BASE);
}
