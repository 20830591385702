@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ($base * 2) ($base * 3) ($base * 2) ($base * 4);
  background: $actionHover;

  .left {
    display: flex;
    align-items: center;
  }

  .description {
    margin-right: $base;
  }
}
