@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  display: flex;
  align-items: center;
}

.button {
  margin-right: $base;
  width: $base * 8;
  height: $base * 8;
}
