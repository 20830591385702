@import '@renderer-ui-library/mui/base/constants.module.scss';

$headerHeightXs: 75px;
$headerHeightSm: 91px;
$headerHeightMd: 132px;

$horizontalPageSpacingOnDesktop: $base * 3;
$horizontalPageSpacingOnMobile: $base * 2;

:export {
  headerHeightXs: $headerHeightXs;
  headerHeightSm: $headerHeightSm;
  headerHeightMd: $headerHeightMd;
}
