@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.navigationBar {
  display: flex;
  justify-content: center;
  background-color: $secondary;
  padding: $base 0;

  .container {
    display: flex;
    justify-content: space-between;
    margin: 0 $horizontalPageSpacingOnMobile;

    @include mediaMin('sm') {
      margin-left: $horizontalPageSpacingOnDesktop;
      margin-right: $horizontalPageSpacingOnDesktop;
    }
  }

  .navigations {
    display: flex;
    gap: $base * 3;
  }
}
