@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';

.mobileMenuWrapper {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex8;
  pointer-events: none;

  .logo {
    display: flex;
  }

  .contentWrapper {
    padding: 0 $base;
  }
}

.mobileMenuWrapperOpen {
  pointer-events: all;
}
