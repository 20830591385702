@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.select {
  text-transform: uppercase;
  font-size: $inputSmallFontSize;
  color: white;
  padding: 0;
  line-height: 1.4375em;

  &:hover {
    color: $secondary;
  }
}

.localesContainer {
  .input {
    color: white;

    &:hover {
      color: $secondary;
    }
  }
  .arrowDown {
    font-size: 20px;
    margin-left: -20px;
    pointer-events: none;
  }
}

.menuItem {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: $inputSmallFontSize;
  padding: 0;

  .menuItemIcon {
    width: $base * 3;
    height: $base * 2;
    margin-right: $base;
  }
}
