@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.wrapper {
  position: relative;
  padding: 0 $horizontalPageSpacingOnMobile;

  @include mediaMin('sm') {
    padding-left: $horizontalPageSpacingOnDesktop;
    padding-right: $horizontalPageSpacingOnDesktop;
  }
}
