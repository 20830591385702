@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.gridWrapper {
  position: relative;
  z-index: 1;

  @include mediaMax('sm') {
    margin-left: -$horizontalPageSpacingOnMobile;
    width: calc(100% + 2 * $horizontalPageSpacingOnMobile);
  }
}

.columnContent {
  text-align: center;

  @include mediaMin('md') {
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.image {
  width: 100%;
  margin-bottom: $base * 3;
}

.stretched {
  @include mediaMin('md') {
    .columnContent {
      max-width: calc(#{$contentMaxWidth} / 3);
    }
  }
}
