@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.container {
  overflow-y: scroll;
}

.list {
  padding: 0;
  margin-top: $base;
  margin-bottom: 0;
  list-style: none;
  max-height: 240px;
}

.input {
  margin-bottom: $base;
}

.hideInputField {
  display: none;
}

.item {
  cursor: pointer;
}

.moreButton {
  margin-top: $base * 2;
}

.chipsWrapper {
  padding-top: $base;
  padding-bottom: $base;
  margin-bottom: $base;
  border-bottom: 1px solid $divider;
}
