@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.mobileNavigationBar {
  display: flex;
  justify-content: space-between;
  background-color: $primary;
  padding: $base * 2;

  @include mediaMin('sm') {
    padding: $base * 3;
  }

  position: sticky;
  top: 0;
  z-index: $zIndex3;

  .mobileMenuButton {
    color: white;
    display: flex;
    align-items: center;
    padding-right: calc($base * 2 - 4px);
    font-size: 35px; // icon size
    cursor: pointer;

    svg {
      margin-left: -4px;
    }
  }
}
