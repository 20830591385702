@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.outerWrapper {
  background-color: $secondary;
  display: block;
  cursor: pointer;
}

.wrapper {
  padding: $base ($base * 2);
  background-color: $orange;
  transform: translateY(-110%);
  animation: scroll 500ms cubic-bezier(0.72, 0.28, 0.09, 1.48);
  animation-delay: 500ms;
  animation-fill-mode: forwards;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 0;
  align-items: center;

  @include mediaMin('md') {
    flex-direction: row;
    justify-content: center;
    gap: $base * 2;
    align-items: center;
  }
}

.countdownText.countdownText {
  line-height: 1.2em;
  color: white;
  font-weight: 700;
}

@keyframes scroll {
  0% {
    transform: translateY(-110%);
  }
  100% {
    transform: translateY(0);
  }
}
