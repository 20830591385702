@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.navigation {
  display: flex;
  gap: $base * 3;

  .entry {
    white-space: nowrap;
    color: $primary;

    a {
      cursor: pointer;
      &:hover {
        color: black;
      }
    }
  }

  // We add custom breakpoints here to hide some of
  // the navigation items when the screen gets smaller
  .hideOnLargeDesktop {
    @include mediaMax('lg') {
      display: none;
    }
  }
  .hideOnSmallDesktop {
    @media (max-width: 1000px) {
      display: none;
    }
  }
}
