@import '@renderer-ui-library/mui/base/colors.module.scss';

.ghost {
  position: relative;
  pointer-events: none;
  overflow: hidden;
  height: 350px;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:before {
    background: $actionDisabledBackground;
    z-index: 1;
  }

  &:after {
    background: linear-gradient(
      0deg,
      transparent 0%,
      white 50%,
      transparent 100%
    );
    opacity: 0.3;
    z-index: 2;
    transform: translateY(-100%) skewY(-20deg);
    animation: glow 3s 1s infinite;
  }
}

@keyframes glow {
  0% {
    transform: translateY(-100%) skewY(-20deg);
  }
  30%,
  100% {
    transform: translateY(100%) skewY(-20deg);
  }
}
