@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

$selectIconWidth: $base * 3;

.select {
  margin: 0;
  text-transform: uppercase;

  :global(.MuiSelect-select) {
    color: white;
    padding-top: $base;
    padding-bottom: $base;
    &:hover {
      color: $secondary;
      text-shadow: 0px 0px 1px $secondary;
    }
  }

  :global(.MuiOutlinedInput-root) {
    color: white;
    &:hover {
      color: $secondary;
      fieldset {
        border-color: $secondary;
      }
    }
  }

  &.sizeMedium {
    :global(.MuiSelect-select) {
      padding-top: $base * 2;
      padding-bottom: $base * 2;
    }
  }

  .fontSmall {
    font-size: $inputSmallFontSize;
  }

  :global(.MuiSelect-icon) {
    width: $selectIconWidth;
    height: $selectIconWidth;
    top: calc(50% - $selectIconWidth/2);
  }

  :global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
    border-color: $pageBackground !important;
  }

  fieldset {
    border-color: $pageBackground;
  }
}

.noOutline {
  fieldset {
    color: $textSecondary;
    border-color: transparent;

    transition: border-color $defaultTransition;
  }

  :global(.Mui-focused) {
    fieldset {
      border-color: $textSecondary;
    }
  }
}

.menuItem {
  text-transform: uppercase;
  display: flex;
}

.menuItemIcon {
  width: $base * 3;
  height: $base * 2;
  margin-right: $base;
  margin-top: calc($base / 4);
}
