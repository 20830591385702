@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';

.container {
  position: fixed;
  bottom: $base * 3;
  right: $base * 3;
  z-index: 999;
}

.wrapper {
  padding: $base * 3;
  position: relative;
  min-height: 300px;
}

.disclaimerWrapper {
  margin-right: $base * 2;
}

.contactWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: $base * 3;
}

.closeIcon {
  position: absolute;
  top: $base * 2;
  right: $base * 2;
  cursor: pointer;
}

.imageWrapper {
  width: $base * 8;
  height: $base * 8;
  border-radius: $base * 4;
  border: 1px solid $outlinedBorder;
  overflow: hidden;
  margin-right: $base * 2;

  img {
    width: 100%;
    height: 100%;
  }
}

.contactInfo {
  display: flex;
  flex: 1;
  flex-direction: column;

  span {
    color: $textDisabled;
    font-weight: 400;
  }
}

.inputGroupPhone {
  display: flex;
  align-items: flex-start;
  margin-top: $base * 2;
  width: 100%;
}

.input {
  width: 100%;
  margin-top: $base * 2;

  @include mediaMin('md') {
    margin-top: 0;
    margin-left: $base;
  }
}

.inputCountryCode {
  min-width: $base * 16;
}

.inputPhone {
  width: 100%;
  margin-left: $base;
}

.expectCall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-right: $base;
  }
}

.callBackFooter {
  display: flex;
  justify-content: space-between;
  margin-top: $base * 2;

  @include mediaMax('md') {
    flex-direction: column-reverse;

    & > div {
      margin-top: $base * 2;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: $base * 3;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;

  @include mediaMin('md') {
    min-width: 552px;
  }
}

.icon {
  display: flex;
  font-size: $base * 10;
  margin-bottom: $base * 3;
}

.title {
  margin-bottom: $base;
}

.message {
  margin-bottom: $base * 3;
  max-width: 400px;
}

.mobileWrapper {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex9;
  pointer-events: none;
}

.mobileWrapperOpen {
  pointer-events: all;
}
