@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: $base * 3;
  padding: $base;
  word-break: break-word;

  @include mediaMin('lg') {
    padding: $base;
    flex-direction: column;
    width: $base * 24;
    height: 100%;
    margin-bottom: 0;
    justify-content: space-between;
  }
}

.fullWidth {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include mediaMin('lg') {
    flex-direction: column;
    align-items: center;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $base * 3;
  margin-right: $base * 2;

  @include mediaMin('lg') {
    width: $base * 10;
    height: $base * 10;
    margin-bottom: $base;
    margin-right: 0;
    border-radius: 50%;
    border: 1px solid $outlinedBorder;
    font-size: $base * 6;
  }

  &.noBorder {
    border: 0;
    margin-bottom: $base;
    margin-right: 0;
    width: $base * 8;
    height: $base * 8;
    font-size: $base * 4;

    @include mediaMin('lg') {
      margin-bottom: 0;
      font-size: $base * 6;
      margin-right: $base;
    }
  }
}

.reverseDirection {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include mediaMin('lg') {
    flex-direction: row;
    align-items: center;
  }
}

.button {
  margin-top: $base * 2;
}
