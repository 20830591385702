@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  background: #fff;
  border: solid 1px $inputBorderNeutral;

  &:first-child {
    border-bottom: none;
  }

  &:not(:first-child) {
    border-top: none;
  }

  @include mediaMax('md') {
    flex-wrap: nowrap;
    display: inline-block;
  }
}

.machines1 {
  width: $compareColWidthPercent * 2;
  min-width: $compareColWidthPx * 2;
}

.machines2 {
  width: $compareColWidthPercent * 3;
  min-width: $compareColWidthPx * 3;
}

.machines3 {
  width: $compareColWidthPercent * 4;
  min-width: $compareColWidthPx * 4;
}

.tableWrapper {
  padding-top: $base * 3;
  border-top: solid 1px $inputBorderNeutral;
}

.noMargin {
  margin: 0;
}

.noPadding {
  padding: 0;
}

.noBorder {
  border: 0;
}

.table {
  break-inside: avoid;
  position: relative;
}

.wrapper .tableRow {
  width: 100%;
  display: flex;
  border-bottom: solid 1px $inputBorderNeutral;

  &:hover {
    background: $backgroundDefault;
  }

  @include mediaMax('md') {
    flex-wrap: nowrap;
    display: flex;
  }
}

$minRowHeight: 46px;
.wrapper .titleRow {
  min-height: $minRowHeight;
  background: $backgroundDefault;
  padding: $base $base * 2;
  border-bottom: 1px solid $inputBorderNeutral;
  display: inline-flex;
  flex: 1;

  &:not(:first-child) {
    border-left: 1px solid $inputBorderNeutral;
  }

  @include mediaMax('md') {
    width: 100%;
  }
}

.wrapper .tableCell {
  min-height: $minRowHeight + 2px;
  display: inline-flex;
  line-height: 36px;
  border: none;
  color: $textPrimary;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  &:not(:first-child) {
    border-left: 1px solid $inputBorderNeutral;
  }

  @include mediaMax('md') {
    width: $compareColWidthPx;
    line-height: 22px;
  }
}

.wrapper .tableCellDisabled {
  color: $textDisabled;

  svg {
    fill: $textDisabled;
  }
}

.marker {
  margin-left: calc(#{$base} / 2);
}
