@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  display: inline-block;
}

.navigationItem {
  color: $primary;

  &:hover {
    color: black;
  }
}

.button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: black;
    text-shadow: 0px 0px 1px $secondary;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $base * 0.5;
  margin-right: -5px;
  font-size: 20px;

  transition: transform $defaultTransition;
}

.open {
  transform: rotate(-180deg);
}

.content {
  padding: $base * 2;
  display: flex;
  flex-direction: column;
  gap: $base;
}

.popover {
  & :global(.MuiPopover-paper) {
    border-radius: 4px;
    overflow: visible;

    &::before {
      content: ' ';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
      position: absolute;
      top: -7px;
      left: calc(50% - 5px);
    }

    :global(.MuiPaper-root) {
      background: transparent;
    }
  }
}
