@import '@renderer-ui-library/mui/base/constants.module.scss';

body {
  .input input {
    padding: $base + 1px $base * 2 $base;
    background: white;
  }

  .inputWithIconStart input {
    padding-left: 0;
  }
  .inputWithIconEnd input {
    padding-right: 0;
  }

  .inputAdornment {
    color: inherit;
  }

  .icon {
    width: $base * 3;
    height: $base * 3;
    color: inherit;
  }
}
