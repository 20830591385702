@import '@renderer-ui-library/mui/base/constants.module.scss';

.countryCodeContainer {
  position: relative;
  z-index: 1;

  :global(.MuiInputBase-input) {
    width: 100%;
    padding-left: 36px !important;
  }
}

.inputFlag {
  position: absolute;
  top: 15px;
  left: $base * 2;
  z-index: 2;
}

.flag {
  margin-right: $base;
  margin-left: 16px; // align with flag in input
}

.option {
  white-space: nowrap;
}

.autocomplete {
  min-width: 120px; // set fixed width to allow for enough space to not wrap or cut the options
}
