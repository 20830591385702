@import '@renderer-ui-library/mui/base/constants.module.scss';

.category {
  margin-bottom: $base;
}

.headline {
  margin-bottom: $base;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  margin-bottom: $base;
}
