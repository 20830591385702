@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $base * 3.5;
}

.featureNumber {
  text-align: center;
  font-family: 'Ginto Nord';
  font-size: $base * 14;
  line-height: 1;
  font-weight: 700;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-image: repeating-linear-gradient(
    transparent,
    transparent $base * 0.5,
    $secondary $base * 0.5,
    $secondary $base
  );
}

.featureTitle {
  width: 100%;
  text-transform: uppercase;
  font-weight: 500 !important;
  background-image: linear-gradient(
    to bottom,
    $secondary $base * 0.25,
    transparent $base * 0.25,
    transparent $base * 0.5,
    $secondary $base * 0.5,
    $secondary $base * 0.75,
    transparent $base * 0.75,
    transparent $base * 1,
    $secondary $base * 1,
    $secondary $base * 1.25,
    transparent $base * 1.25,
    transparent $base * 1.5,
    $secondary $base * 1.5,
    $secondary $base * 1.75
  );
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 1px $base * 1.75;
  padding-bottom: $base * 2.5;
  margin-bottom: $base !important;
}

.highlight {
  color: $textInvertedPrimary;
}
