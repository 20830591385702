@import '@renderer-ui-library/mui/base/constants.module.scss';

.iconWrapper {
  width: $base * 6;
  height: $base * 6;
  padding: calc(#{$base} / 2);

  :global(.MuiSvgIcon-root) {
    width: 100%;
    height: 100%;
  }
}
