@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';

.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 2px;

  @include mediaMin('md') {
    margin-right: $base;
  }
}

.title {
  & h4 {
    font-weight: $subtitle1FontWeight;
  }
}
