@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

$topPadding: $base * 3;
$sidePadding: $base * 3;

$topPaddingMobile: $base * 3;
$sidePaddingMobile: $base * 2;

.dialogContent.dialogContent {
  position: relative;
  padding: $topPaddingMobile $sidePaddingMobile;
  display: flex;
  flex-direction: column;

  @include mediaMin('md') {
    padding: $topPadding $sidePadding;
  }
}

.mobileCenterAlign {
  @include mediaMax('md') {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $base * 2;
}

.noTitle {
  position: absolute;
  top: $topPadding;
  right: $sidePadding;
}

.close {
  @include mediaMax('md') {
    display: none;
  }
}

.content {
  margin: $base * 3 0;
  padding-bottom: 40px;
}

.buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: right;
  padding: $topPadding $sidePadding;

  * + * {
    margin-left: $base * 2;
  }
}

.scrollContainerWrapper {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.scrollContainer {
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @include mediaMin('md') {
    height: 250px;
  }
}
