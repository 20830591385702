@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';

.buttonWrapper {
  display: inline-block;

  .button {
    min-width: 200px;
    max-width: 100%;
    min-height: $base * 5;
  }

  .noMinWidth {
    min-width: auto;
  }

  .fullHeight {
    height: 100%;
  }

  :global(.MuiButton-sizeSmall) {
    font-size: $buttonSmallFontSize;
    line-height: $buttonSmallLineHeight;
    min-height: $base * 4;
  }
  :global(.MuiButton-sizeMedium) {
    font-size: $buttonMediumFontSize;
    line-height: $buttonMediumLineHeight;
  }
  :global(.MuiButton-sizeLarge) {
    font-size: $buttonLargeFontSize;
    line-height: $buttonLargeLineHeight;
  }
}

.fullWidth {
  display: block;
  width: 100%;
}

.flex {
  display: flex;
  flex: 1;
}
