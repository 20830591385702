@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.imageWrapper {
  width: 100%;
  max-width: 600px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: 1px solid $outlinedBorder;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mediaMin('md') {
    align-items: flex-start;
  }
}

.contentInfoWrapper {
  display: flex;
  text-align: center;
  align-items: center;

  & p {
    margin: 0;
  }

  & :nth-child(2) {
    margin: 0 calc($base / 2) 0;
  }
}

.link {
  color: $primaryLight;
}
