@import '@renderer-ui-library/mui/base/constants.module.scss';

.wrapper {
  margin-bottom: $base * 2;

  .header {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: $base;
  }
}
