@import '@renderer-ui-library/mui/base/constants.module.scss';

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $base * 2 0 $base;

  button,
  a {
    text-transform: initial;
    margin-bottom: $base;
  }
}

.iconButton {
  margin-right: $base;
  font-size: $base * 2.5;
}
