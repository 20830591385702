@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.testimonailSlider {
  margin-bottom: $base * 6;

  @include mediaMin('md') {
    margin-bottom: 0;
  }

  :global(.splide__pagination) {
    bottom: -$base * 4 !important;
  }

  :global(.splide__pagination__page) {
    height: 13px !important;
    width: 13px !important;
    background: $parisWhite !important;
    transform: scale(1) !important;
    opacity: 1 !important;
  }

  :global(.splide__pagination__page.is-active) {
    background: $primary !important;
  }
}

.testimonialHighlight {
  :global(.splide__pagination__page.is-active) {
    background: $secondary !important;
  }
}

.testimonialContent {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;

  @include mediaMin('md') {
    flex-direction: row;
    padding-right: $base * 17;

    &::after {
      content: '';
      position: absolute;
      background: url(../../assets/quote.svg) right center no-repeat;
      background-size: contain;
      width: $base * 15;
      height: $base * 10.5;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.mediaWrapper {
  width: 168px;
  min-width: 168px;
  height: 168px;
  margin-right: $base * 3;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid $secondary;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include mediaMax('md') {
    margin-right: 16px;
    width: 64px;
    min-width: 64px;
    height: 64px;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;

  @include mediaMax('md') {
    padding-right: $base * 11;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      display: block;
      background: url(../../assets/quote.svg) right center no-repeat;
      width: $base * 10;
      height: $base * 7;
      top: 0;
      right: 0;
    }
  }
}

.mobileTitle {
  display: none;

  @include mediaMax('md') {
    display: block;
  }
}

.title {
  display: flex;
  flex-direction: row;

  @include mediaMax('md') {
    display: none;
  }

  h6:last-child {
    margin-left: $base * 0.5;
  }
}

.text {
  margin: $base * 1.5 0;
}

.bottomText {
  color: rgba($greenDark, 0.6);
}

.highlight {
  color: $textInvertedSecondary;
}
