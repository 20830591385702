@import '@renderer-ui-library/mui/base/constants.module.scss';

.locationContentTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $base;
  margin-top: $base;
}

.icon {
  margin-right: $base;
}
