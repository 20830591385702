@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.desktopSearchBar {
  display: flex;
  justify-content: center;
  background-color: $primary;
  padding: $base * 3 0;

  .container {
    margin: 0 $horizontalPageSpacingOnMobile;
    row-gap: $base * 2;

    @include mediaMin('sm') {
      margin-left: $horizontalPageSpacingOnDesktop;
      margin-right: $horizontalPageSpacingOnDesktop;
      row-gap: $base * 3;
    }
  }
  .logo {
    color: white;
    position: relative;
    display: block;
    width: 88px;
    height: 24px;

    @include mediaMin('md') {
      width: 146px;
      height: 37px;
    }
  }

  .localesWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .locales {
    margin-right: -5px;
    font-size: $inputSmallFontSize;
  }
}
