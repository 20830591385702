@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.grow {
  &,
  :global(.MuiCard-root),
  :global(.MuiCardActionArea-root),
  :global(.MuiCardContent-root) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.content.content {
  padding: calc($base / 2) $base;

  @include mediaMin('md') {
    padding: $base ($base * 2) 0;
  }
}

.content .title {
  padding-bottom: 0.3em;
  font-weight: 500;
  text-transform: uppercase;
  font-size: $subtitle1FontSize;
  color: $primary;
  line-height: 1.3;

  @include mediaMin('md') {
    font-size: $body1FontSize;
  }
}

.media {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  background-repeat: no-repeat;
  position: absolute;
  z-index: 10;
  transform: rotate(15deg) scale(0.5);
  width: 100%;
  aspect-ratio: 1.5;
}

.grayscale {
  filter: grayscale(50%);
}

.shareWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 $base $base * 2;

  @include mediaMin('md') {
    padding: 0 $base * 2 $base * 2;
  }
}

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .vatInfo {
    font-size: $inputSmallFontSize;
    color: $textSecondary;
  }
}

.shareWrapper .shareWrapperPriceHeadline {
  font-size: $subtitle1FontSize;
  font-weight: 500;
}

.shareWrapper .shareWrapperPriceOnRequestHeadline {
  font-size: $body1FontSize;
  font-weight: 500;
}

.leasingTag {
  border: 1px solid $orange;
  color: $orange;
  padding: 0 $base;
  margin: 0 $base $base $base;
  text-align: center;

  @include mediaMin('md') {
    margin: 0 ($base * 2) $base ($base * 2);
  }
}
