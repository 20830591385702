@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.modal {
  width: 100%;
  height: 100%;
  background: $black80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;

  @include mediaMax('lg') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include mediaMin('lg') {
    width: 960px;
    height: 100%;
  }
}

.content {
  flex-direction: column;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.header {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding: $base * 2;

  @include mediaMax('md') {
    padding: $base * 2 $base;
  }
}

.title {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;

  :global(.splide) {
    height: 100%;
  }

  :global(.splide__track) {
    height: 100%;
  }

  :global(.splide__slide) {
    width: 100% !important;
    height: 100% !important;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }
  }
}

.closeButton {
  cursor: pointer;
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.thumbnails,
.main {
  :global(.splide__arrow) {
    background: $black80;
    width: $base * 6;
    height: $base * 6;

    @include mediaMax('lg') {
      visibility: hidden;
    }

    svg {
      fill: white;
    }
  }
  :global(.splide__arrow):disabled {
    cursor: default;
  }
}
.thumbnails {
  margin-top: $base * 3;
  margin-bottom: $base * 3;

  :global(.splide__slide) {
    opacity: 0.6;
    border: 2px solid transparent !important;
  }

  :global(.splide__slide.is-active) {
    opacity: 1;
    border-color: $secondary !important;
  }
}
