.card {
  margin: 0px 0px 25px;
  box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.15);

  & * {
    text-decoration: none;
  }

  &:hover {
    transform: translateY(-0.125em);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    transition:
      box-shadow 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
}
