@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/zIndexes.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.mapBackground {
  width: 100%;
  display: block;
  height: 175px;
  background: #fff;
  position: absolute;
  transition: height 0.25s ease-in;
  border: 1px solid $divider;
}

.mapBackgroundExpanded {
  height: 325px;
  transition: height 0.25s ease-out;

  @include mediaMin('md') {
    height: 525px;
  }
}

.mapContainer {
  display: grid;
  align-items: center;
  margin: $base 0 calc($base * 5);
  height: 150px;
  transition: height 0.25s ease-in;
  width: 100%;
  position: relative;

  @include mediaMin('md') {
    margin: calc($base * 3) 0;
    background: $greyLight;
  }
}

.mapContainerExpanded {
  height: 300px;
  transition: height 0.25s ease-out;

  @include mediaMin('md') {
    height: 500px;
  }
}

.mapContainer .mapTitleWrapper {
  align-self: flex-end;
  z-index: 2;
  justify-self: center;
  box-shadow: 0px 8px 16px rgba(72, 88, 133, 0.16);
  bottom: 15px;

  border-radius: $base;
  padding: $base 2 * $base;
  position: relative;
  background-color: white;

  &:hover {
    background-color: #fff !important;
    box-shadow: 0 8px 16px rgba(72, 88, 133, 0.24);

    h6 {
      color: $primaryLight;
    }
  }
}

.mapTitleWrapperExpanded {
  transition: margin-top 0.25s ease-out;
}

.marker {
  height: 25px;
  width: 30px;
  border-radius: calc($base / 2);
  background-color: $primary;
  background-image: url('../../assets/efarm-brand-icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: 15px;
}

.map {
  min-height: 150px;
  width: calc(100% - 20px);
  height: 100%;
  z-index: 2;
  justify-self: center;
  transition: all 0.25s ease-out;
}

.mapExpanded {
  min-height: 300px;
  transition: all 0.25s ease-in;

  @include mediaMin('md') {
    min-height: 500px;
  }
}

.trustPilotWrapper {
  display: flex;
  justify-content: center;

  @include mediaMin('md') {
    justify-content: flex-end;
  }
}

.mapContainer .happyCustomerCta {
  font-size: $buttonSmallFontSize;

  @include mediaMin('md') {
    font-size: $h6FontSize !important;
  }
}
