@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  padding-top: $base * 4;
  padding-bottom: $base * 4;
  padding-left: $horizontalPageSpacingOnMobile;
  padding-right: $horizontalPageSpacingOnMobile;

  @include mediaMin('md') {
    padding-top: $base * 8;
    padding-bottom: $base * 8;
  }

  @include mediaMin('sm') {
    padding-left: $horizontalPageSpacingOnDesktop;
    padding-right: $horizontalPageSpacingOnDesktop;
  }

  &.noSpacingTop {
    padding-top: 0;
  }

  &.noSpacingBottom {
    padding-bottom: 0;
  }
}

.fullWidth {
  padding-left: 0;
  padding-right: 0;
}

.highlight {
  background: $backgroundPrimary;
}

.highlightDark {
  background: $backgroundDefault;
}
