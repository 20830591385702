@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';

.searchDesktopWrapper {
  background: white;
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
  padding: $base * 2 0;
  transition: all $defaultTransition;
  transform: translateY(-100%);
  visibility: hidden;
}

.shim {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $shim;
  opacity: 0;
  pointer-events: none;
  transition: opacity $defaultTransition;

  &.show {
    opacity: 1;
    pointer-events: all;
    transition: opacity $defaultTransition;
  }
}

.searchMobileWrapper {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex8;
  pointer-events: none;
}

.open {
  pointer-events: all;
  visibility: visible;
  transform: translateY(0px);
  transition: all $defaultTransition;
}

.searchHeaderContent {
  display: flex;
  align-items: center;
  margin-bottom: $base * 2;

  p {
    margin-left: $base;
  }
}

.searchWrapperOpen {
  pointer-events: all;
}

.searchIconWrapper {
  width: fit-content;
  padding: 6px $base 0px $base;
  margin: 4px 0;
  cursor: pointer;
  color: white;
  &:hover {
    color: $secondary;
  }
}

.clickable {
  cursor: pointer;
  color: $primary;
  &:hover {
    color: $secondary;
  }
}

.noMargin {
  margin-top: 0 !important;
}

.searchOverlayWrapper {
  display: flex;
  justify-content: flex-end;
}
