@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.footer {
  padding: $base * 4 $horizontalPageSpacingOnMobile;
  background: $primary;

  @include mediaMin('sm') {
    padding-left: $horizontalPageSpacingOnDesktop;
    padding-right: $horizontalPageSpacingOnDesktop;
  }
}

.logoWrapper {
  display: flex;
  height: $base * 3;
  width: $base * 11;
  margin-bottom: $base * 2;
  position: relative;

  @include mediaMin('lg') {
    height: $base * 3.5;
    width: $base * 12.75;
    margin-bottom: $base * 3;
  }
}

.locales {
  display: none;

  @include mediaMin('lg') {
    display: block;
    width: 150px;
    margin-top: $base * 3;
    margin-bottom: $base * 3;
  }
}

.widgetContainer {
  @include mediaMin('lg') {
    display: flex;
    flex: 1;
  }
}

.trustPilot {
  color: $textInvertedPrimary;
  padding: $base * 1.5 0;
  @include mediaMin('lg') {
    border: 2px solid $secondary;
  }
}

.localesMobile {
  display: block;
  margin: $base * 3 0;

  :global(.MuiFormControl-root) {
    width: 100%;
  }

  @include mediaMin('lg') {
    display: none;
  }
}

.entry {
  margin-top: $base;
  font-size: $body1FontSize;

  &:nth-child(2) {
    margin-top: $base * 2;
  }

  a {
    color: $textInvertedPrimary;
  }

  a,
  button {
    &:hover,
    &:focus,
    &:active {
      color: $secondary;
      text-shadow: 0 0 1px $secondary;
      outline: 0;
    }
  }

  @include mediaMin('lg') {
    font-size: $body2FontSize;
  }
}

.copyright {
  margin-top: $base * 3;

  p {
    color: $textInvertedSecondary;
  }

  @include mediaMin('lg') {
    margin-bottom: $base;
  }
}
