@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';
@import '@renderer-ui-library/base/constants.module.scss';

.mobileMenuBar.mobileMenuBar {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: $headerHeightXs;
  min-height: 41px;
  padding: 0 $base * 3;
  box-sizing: content-box;
  margin-left: $base * -1;
  margin-right: $base * -3;
  background: $backgroundDefault;
  border-bottom: 1px solid $divider;
  z-index: $zIndex8;
  overflow: auto;

  @include mediaMin('sm') {
    top: $headerHeightSm;
  }

  @include mediaMin('md') {
    display: none;
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  align-items: center;
  margin-right: $base * 2;
  text-transform: uppercase;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
}

.smallIcon {
  width: 18px;
  height: 18px;
  margin-right: $base;

  :global(.MuiSvgIcon-root) {
    width: 100%;
    height: 100%;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: $base;
}
