@import '@renderer-ui-library/mui/base/constants.module.scss';

.list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding-top: $base;
  }

  :global(.MuiListItem-root) {
    padding: $base 0;
    align-items: baseline;
  }

  :global(.MuiListItemIcon-root) {
    display: flex;
    width: $base * 2.5;
    min-width: $base * 2.5;
    height: $base * 2.5;
    margin-right: $base * 2;
    transform: translateY(
      20%
    ); // little hack to center icon in front of first line of text
  }
  :global(.MuiSvgIcon-root) {
    width: $base * 2.5;
    min-width: $base * 2.5;
    height: $base * 2.5;
  }
}
