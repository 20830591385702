@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';

.link {
  color: $lightPetrol;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.textContainer {
  margin-bottom: $base * 4;
}

.entryContainer {
  .list {
    padding: 0;
    margin: 0 0 $base * 4;

    li {
      list-style-type: none;
      margin-bottom: $base * 0.5;
    }
  }
}
