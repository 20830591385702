$h1FontSize: 72px;
$h1LineHeight: 1.17;
$h1FontWeight: 700;
$h1FontSizeMobile: 24px;
$h1LineHeightMobile: 1.33;

$h2FontSize: 48px;
$h2LineHeight: 1.2;
$h2FontWeight: 500;
$h2FontSizeMobile: 20px;
$h2LineHeightMobile: 1.6;

$h3FontSize: 34px;
$h3LineHeight: 1.23;
$h3FontWeight: 700;
$h3FontSizeMobile: 16px;
$h3LineHeightMobile: 1.6;

$h4FontSize: 24px;
$h4LineHeight: 1.33;
$h4FontWeight: 700;
$h4FontSizeMobile: 16px;
$h4LineHeightMobile: 1.6;

$h5FontSize: 20px;
$h5LineHeight: 1.6;
$h5FontWeight: 700;
$h5FontSizeMobile: 16px;
$h5LineHeightMobile: 1.6;

$h6FontSize: 16px;
$h6LineHeight: 1.6;
$h6FontWeight: 700;
$h6FontSizeMobile: 16px;
$h6LineHeightMobile: 1.6;

$subtitle1FontSize: 18px;
$subtitle1LineHeight: 1.75;
$subtitle1FontWeight: 500;
$subtitle1FontSizeMobile: 14px;
$subtitle1LineHeightMobile: 1.75;

$subtitle2FontSize: 14px;
$subtitle2LineHeight: 1.57;
$subtitle2FontWeight: 400;
$subtitle2FontSizeMobile: 12px;
$subtitle2LineHeightMobile: 1.57;

$body1FontSize: 16px;
$body1LineHeight: 1.5;
$body1FontSizeMobile: 14px;
$body1LineHeightMobile: 1.5;

$body2FontSize: 14px;
$body2LineHeight: 1.43;
$body2FontSizeMobile: 12px;
$body2LineHeightMobile: 1.43;

$buttonLargeFontSize: 15px;
$buttonLargeLineHeight: 1.47;
$buttonMediumFontSize: 14px;
$buttonMediumLineHeight: 1.71;
$buttonSmallFontSize: 13px;
$buttonSmallLineHeight: 1.69;

$inputFontSize: $body1FontSize;
$inputLineHeight: 1.71;

$inputSmallFontSize: 12px;

:export {
  h1FontSize: $h1FontSize;
  h1LineHeight: $h1LineHeight;
  h2FontSize: $h2FontSize;
  h2LineHeight: $h2LineHeight;
  h3FontSize: $h3FontSize;
  h3LineHeight: $h3LineHeight;
  h4FontSize: $h4FontSize;
  h4LineHeight: $h4LineHeight;
  h5FontSize: $h5FontSize;
  h5LineHeight: $h5LineHeight;
  h6FontSize: $h6FontSize;
  h6LineHeight: $h6LineHeight;
  h1FontSizeMobile: $h1FontSizeMobile;
  h1LineHeightMobile: $h1LineHeightMobile;
  h2FontSizeMobile: $h2FontSizeMobile;
  h2LineHeightMobile: $h2LineHeightMobile;
  h3FontSizeMobile: $h3FontSizeMobile;
  h3LineHeightMobile: $h3LineHeightMobile;
  h4FontSizeMobile: $h4FontSizeMobile;
  h4LineHeightMobile: $h4LineHeightMobile;
  h5FontSizeMobile: $h5FontSizeMobile;
  h5LineHeightMobile: $h5LineHeightMobile;
  h6FontSizeMobile: $h6FontSizeMobile;
  h6LineHeightMobile: $h6LineHeightMobile;

  subtitle1FontSize: $subtitle1FontSize;
  subtitle1LineHeight: $subtitle1LineHeight;
  subtitle1FontWeight: $subtitle1FontWeight;
  subtitle1FontSizeMobile: $subtitle1FontSizeMobile;
  subtitle1LineHeightMobile: $subtitle1LineHeightMobile;
  subtitle2FontSize: $subtitle2FontSize;
  subtitle2LineHeight: $subtitle2LineHeight;
  subtitle2FontWeight: $subtitle2FontWeight;
  subtitle2FontSizeMobile: $subtitle2FontSizeMobile;
  subtitle2LineHeightMobile: $subtitle2LineHeightMobile;
  body1FontSize: $body1FontSize;
  body1LineHeight: $body1LineHeight;
  body2FontSize: $body2FontSize;
  body2LineHeight: $body2LineHeight;
  body1FontSizeMobile: $body1FontSizeMobile;
  body1LineHeightMobile: $body1LineHeightMobile;
  body2FontSizeMobile: $body2FontSizeMobile;
  body2LineHeightMobile: $body2LineHeightMobile;
  buttonFontSize: $buttonMediumFontSize;
  buttonLineHeight: $buttonMediumLineHeight;
  h1FontWeight: $h1FontWeight;
  h2FontWeight: $h2FontWeight;
  h3FontWeight: $h3FontWeight;
  h4FontWeight: $h4FontWeight;
  h5FontWeight: $h5FontWeight;
  h6FontWeight: $h6FontWeight;
  inputFontSize: $inputFontSize;
  inputLineHeight: $inputLineHeight;
}
