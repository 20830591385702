@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.inputGroup {
  margin-bottom: $base * 2;

  @include mediaMin('md') {
    display: flex;
    align-items: flex-start;
  }
}

.inputGroupPhone {
  display: flex;
  align-items: flex-start;
  margin-bottom: $base * 2;
}

.input {
  width: 100%;
  margin-top: $base;

  @include mediaMin('md') {
    margin-top: 0;
    margin-left: $base;
  }
}

.inputCountryCode {
  min-width: $base * 16;
}

.inputPhone {
  width: 100%;
  margin-left: $base;
}

.leasingDisclaimerLongText {
  margin-top: $base;
}
