@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.button.button {
  &:hover {
    // this keeps the border color of the button without hover
    border-color: rgba(84, 165, 70, 0.5);
  }

  &:not(:first-child) {
    border-left: none;
  }
  &:not(:last-child) {
    border-right: none;
  }

  @include mediaMax('md') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: $base;
    color: $textPrimary;
    text-transform: initial;
  }

  .checkIcon {
    color: $secondary;
  }
}

.selected.selected {
  pointer-events: none;

  .checkIcon {
    visibility: visible;
  }

  @include mediaMin('md') {
    background: $secondary;
  }

  @include mediaMax('md') {
    color: $secondary;
  }
}
