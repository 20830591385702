@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';

.wrapper {
  margin-right: 16px;
  margin-top: $base * 1.5;

  img {
    width: $base * 7;
    height: $base * 5;
  }

  @include mediaMax('lg') {
    margin-right: $base;
    margin-top: $base * 0.75;

    img {
      width: $base * 3;
      height: $base * 2;
    }
  }
}
