@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.wrapper {
  position: relative;
  width: 100%;
  padding: 0 $base * 2;

  :global(.swiper-button-prev) {
    display: none !important;
  }

  :global(.swiper-button-next) {
    display: none !important;
  }
}

.swiper {
  overflow: hidden;

  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.swiperSlide {
  width: 250px;
}

.mobileWrapper {
  overflow: auto;
  white-space: nowrap;
  margin-right: -$base * 2;

  &::-webkit-scrollbar {
    display: none;
  }
}

.column {
  width: $base * 22;
  display: inline-block;
  padding: 0 $base;

  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include mediaMin('sm') {
    width: $base * 40;
  }
}

.image {
  width: 100%;
  margin-bottom: $base * 3;
}

.swiperPrev,
.swiperNext {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  opacity: 1;
  z-index: 999;
  transform: translateY(-20px);
  background: #fff;
  border: 1px solid $inputBorderNeutral;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $shim;
}

.swiperPrev {
  left: -2px;
}

.swiperNext {
  right: -2px;
}

.hideNavigate {
  display: none;
}
