@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.gridWrapper {
  position: relative;
}

.columnContent {
  text-align: center;

  @include mediaMin('md') {
    display: flex;
    flex-direction: column;
    max-width: 280px;
    height: 100%;
    text-align: left;
  }
}

.iconWrapper {
  display: inline-block;
  color: $textSecondary;
  margin-bottom: $base;
  font-size: 28px;

  @include mediaMax('md') {
    margin-inline: auto;
  }
}

.iconHighlight {
  color: white;
}

.mediumIcon {
  font-size: 48px;
}
.largeIcon {
  font-size: 64px;
}

.iconColorDeepGreen {
  color: $deepGreen;
}

.iconColorAuthenticGreen {
  color: $authenticGreen;
}

.iconColorActionPetrol {
  color: $actionPetrol;
}

.iconColorActionOrange {
  color: $actionOrange;
}
