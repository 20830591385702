@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.salesConatiner {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: $base * 1.75;

    @include mediaMax('md') {
      text-align: center;
    }
  }

  span {
    font-size: $base * 1.5;
  }
}

.image {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin-bottom: $base;
  border: 1px solid $outlinedBorder;
}
