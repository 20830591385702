@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.image {
  margin-bottom: $base * 3;

  & img {
    width: 100%;
  }
}

.gridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid $secondary;
  border-bottom: 2px solid $secondary;
}

.displayMobile {
  .gridItem {
    border-right: 2px solid $secondary;
    border-bottom: 2px solid $secondary;

    @include mediaMax('md') {
      &:nth-of-type(3n) {
        border-right: none;
      }

      @for $i from 7 through 9 {
        &:nth-of-type(#{$i}n) {
          border-bottom: none;
        }
      }
    }

    @include mediaMin('md') {
      &:nth-of-type(4n) {
        border-right: none;
      }

      @for $i from 5 through 8 {
        &:nth-of-type(#{$i}n) {
          border-bottom: none;
        }
      }

      @for $i from 9 through 36 {
        &:nth-of-type(#{$i}n) {
          display: none;
        }
      }
    }
  }

  .mediaContainer {
    height: $base * 9.375;
    width: $base * 9.375;
    padding-top: $base * 1.5;

    @include mediaMin('md') {
      height: $base * 18.75;
      width: $base * 18.75;
      padding-top: $base * 3;
    }
  }
}
