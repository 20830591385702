@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  position: relative;
  border: 1px solid $inputBorderNeutral;
  cursor: pointer;

  &:hover:not(.disabled) {
    border-color: $inputBorderHover;
  }

  &.open {
    border-color: $border;
  }
}

.rangeInputWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;

  &:not(.show) {
    pointer-events: none;
  }
}

.placeholder {
  color: $textSecondary;
}

.disabled .placeholder {
  color: $textDisabled;
}

.rangeInput {
  padding: $base;
  background: white;
  min-width: 320px;
  transform: translateX(-50%);
  margin-top: 2px;
  margin-left: 50%;
}

.textField {
  width: 100%;
  background: white;
  padding: ($base - 2) 4 * $base ($base - 2) 1.5 * $base;
  position: relative;
  border: 1px solid white;
}
.open .textField {
  border-color: $inputBorderFocus;
}

.textField .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
}

.valueWrapper {
  display: flex;
  justify-content: space-between;

  .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.unit {
  color: $textSecondary;
  margin: 0 $base;
}

.disabled {
  color: $textDisabled;
  cursor: default;
}
