@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.logo {
  position: relative;
  margin-bottom: $base * 2;

  img {
    width: 100px;

    @include mediaMin('md') {
      width: 150px;
    }
  }
}

.leasingLogo {
  position: relative;
  margin-bottom: $base * 2;

  img {
    width: 132px;
    height: 36px;
    aspect-ratio: auto 132 / 36;

    @include mediaMin('md') {
      width: 187px;
      height: 51px;
      aspect-ratio: auto 187 / 51;
    }
  }
}

.content {
  position: relative;
  width: 100%;
  background: rgba($primary, 75%);
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 0px $base * 2;
}

.icon {
  svg {
    fill: $secondary;
    font-size: $base * 6;
    width: $base * 6;
    height: $base * 6;

    @include mediaMax('md') {
      font-size: $base * 3;
      width: $base * 3;
      height: $base * 3;
    }
  }
}

.title {
  margin-top: $base * 2;
  margin-bottom: $base * 4.5;
}

.titleSmMargin {
  margin-bottom: $base * 2;
}

.button {
  color: $orange;
  display: block;
  width: 100%;

  > div {
    display: block;
  }

  button {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    margin: auto;
  }
}
