// MARGINS & PADDINGS
$base: 8px;
$contentMaxWidth: 1200px;

$borderRadius: $base;

$defaultTransition: 0.4s ease;
$slowTransition: 0.6s ease;

$iconExtraSmallFontSize: 20px;

$cardWidthMd: 40rem;

$compareColWidthPx: 160px;
$compareColWidthPercent: 25%;

$headerHeightMobile: 20px;

$uspHeaderHeight: 20px;

$mainNavItemMargin: 16px;

$headerHeight: 20px;

:export {
  base: $base;
}
