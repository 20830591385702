@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.wrapper {
  @include mediaMax('md') {
    overflow-x: auto;
    margin-right: -$base * 2;
  }
}

.slides {
  display: flex;
  flex: 1;
}

.control {
  width: $compareColWidthPercent;
  min-width: 160px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  background: #fff;
  border-top: solid 1px $inputBorderNeutral;
  border-left: solid 1px $inputBorderNeutral;
  box-sizing: border-box;

  label {
    display: none;
  }
}

.images {
  display: inline-flex;
  justify-content: flex-start;
  border-left: solid 1px $inputBorderNeutral;
  box-sizing: border-box;
  width: $compareColWidthPercent * 3;
}

.content {
  margin: 0;
}

.container {
  width: 100%;
}

$cellPadding: $base * 2;

.buttonCell {
  width: 25%;
  min-width: $compareColWidthPx;
  padding: $cellPadding;
  box-sizing: border-box;
}

.buttons {
  display: flex;

  .button {
    min-width: $compareColWidthPx - 2 * $cellPadding;
  }
}
