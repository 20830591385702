@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  background: #fff;
  padding-bottom: calc($base * 5);
  box-sizing: content-box;

  @include mediaMin('md') {
    padding: calc($base * 3) calc($base * 2) calc($base * 5);
    border: 1px solid $divider;
    margin-right: $base * 2;
  }
}

.desktop {
  display: none;

  @include mediaMin('md') {
    display: block;
  }
}

.noMachine {
  padding: $base * 3 $base * 2;
  text-align: center;
}

.icon {
  font-size: $base * 6;
  margin-bottom: $base;
}

.buttons {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: $base $base * 2;
  background: white;
  border-top: 1px solid $divider;
  z-index: 1;

  @include mediaMin('md') {
    display: none;
  }
}

.button {
  flex-grow: 1;
  & + & {
    margin-left: $base;
  }
}
