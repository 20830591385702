@import '@renderer-ui-library/mui/base/colors.module.scss';

.slider {
  :global(.splide__track) {
    height: 100%;
  }

  :global(.splide__arrow) {
    background: $black80;

    svg {
      fill: white;
    }
  }

  :global(.splide__arrow):disabled {
    cursor: default;
  }
}

.image {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.cover {
  :global(.splide__track) {
    height: 100%;
  }
}
