@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.link {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.linkContent {
  flex: 1;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;

  @include mediaMax('md') {
    div:not(:first-child) {
      margin: 0;
    }
  }
}

@include mediaMin('md') {
  .rowContent {
    flex-direction: row;
  }
}

.text {
  margin-bottom: $base * 3;
}

.item {
  flex: 1;
  display: flex;
  flex-direction: column;

  &.paddingSides {
    @include mediaMax('sm') {
      padding: 0 $horizontalPageSpacingOnMobile;
    }
  }
}

.noMarginBottom {
  margin-bottom: 0;
}

.button {
  margin-top: $base * 3;
}
