@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  padding: ($base * 3) ($base * 2);
  background: $actionHover;
}

.left {
  display: flex;
  align-items: center;
}

.image {
  width: $base * 8;
  height: $base * 8;
  margin-right: $base;
  border-radius: $borderRadius;
  overflow: hidden;
  background: white;
}

.info.info {
  text-transform: uppercase;
}
