@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.logoWrapper {
  display: flex;
  margin-bottom: calc($base * 2);

  @include mediaMax('sm') {
    flex-direction: column;
    align-items: center;
    margin-bottom: $base * 2;
  }

  img {
    width: 159px;
    height: 51px;

    @include mediaMax('sm') {
      width: 127px;
      height: 41px;
      margin-bottom: $base;
    }
  }
}

.container {
  margin: calc($base * 3) 0;
  border-bottom: $base * 0.5 solid $petrol;
}

.buttonContainer {
  padding-bottom: calc($base * 3);
}

.button {
  border: 1px solid $orange !important;
  color: $orange !important;
}

.leasingPriceInfoIcon {
  color: $orange;
  margin-left: calc($base / 2);
  font-size: $h4FontSize;
}

.leasingText {
  padding-left: $base * 2;

  @include mediaMax('sm') {
    padding-left: 0;
  }
}
