@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.grow {
  &,
  :global(.MuiCard-root),
  :global(.MuiCardActionArea-root),
  :global(.MuiCardContent-root) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.content.content {
  padding: $base;

  .title {
    padding-bottom: 0.3em;
    font-weight: 500;
    text-transform: uppercase;
  }
  @include mediaMin('md') {
    padding: $base ($base * 2) ($base * 2);
  }
}

.media {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  background-repeat: no-repeat;
  position: absolute;
  z-index: 10;
  transform: rotate(15deg) scale(0.5);
  width: 100%;
  aspect-ratio: 1.5;
}

.grayscale {
  filter: grayscale(50%);
}
