@import '@renderer-ui-library/mui/base/constants.module.scss';

.person {
  display: flex;
  align-items: center;
  margin: $base * 2 0;
}

.imageWrapper {
  position: relative;
  width: $base * 8;
  height: $base * 8;
  border-radius: 50%;
  overflow: hidden;
  margin-right: $base;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
