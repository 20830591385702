@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.tableWrapper {
  padding: $base * 3 0;
  @include mediaMin('md') {
    columns: 2;
    margin: 0 $base * 2;
  }
}

.noPadding {
  padding: 0;
}

.table {
  break-inside: avoid;
  margin-bottom: $base * 3;
}
