@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
.grow {
  &,
  :global(.MuiCard-root),
  :global(.MuiCardActionArea-root),
  :global(.MuiCardContent-root) {
    display: block;

    @include mediaMin('md') {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }
}

.content.content {
  padding: $base $base * 4 $base $base * 2 !important;
  border-bottom: 1px solid $divider;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mediaMin('md') {
    border-bottom: none;
    padding: $base * 3 $base * 4 $base * 3 $base * 3 !important;
  }

  h4 {
    font-size: $h5FontSize;
    height: 100%;

    @include mediaMin('md') {
      font-size: $h4FontSize;
      line-height: $h4LineHeight;
    }
  }
}

.media {
  height: auto;
  width: 209px;
  aspect-ratio: 3 / 2;

  @include mediaMin('md') {
    width: 288px;
  }
}

.cardArea {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  width: 100%;
  position: relative;

  @include mediaMin('md') {
    flex-direction: row;
  }
}
