@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.image {
  position: relative;
  width: 100%;
  height: 100%;
  object-position: center;
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

.thumbnails {
  display: none;

  @include mediaMin('sm') {
    display: block;
  }
}

.pagination {
  position: absolute;
  right: $base;
  bottom: $base;
  background: rgba(black, 0.5);
  padding: 0 2px;
  color: #fff;
}

.clickable {
  cursor: pointer;
}

.thumbnails,
.main {
  position: relative;

  :global(.splide__arrow) {
    background: $black80;

    @include mediaMax('md') {
      background: transparent;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    svg {
      fill: white;
    }
  }

  :global(.splide__arrow):disabled {
    cursor: default;
  }
}
.thumbnails {
  :global(.splide__slide) {
    opacity: 0.6;
    border: 2px solid transparent !important;
  }

  :global(.splide__slide.is-active) {
    opacity: 1;
    border-color: $secondary !important;
  }
}

.soldStamp {
  transform: scale(0.65) rotate(25deg);
  width: 100%;
}

.soldOverlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
}

.shareButtonWrapper {
  position: absolute;
  right: 0;
  z-index: 1;
  background: rgba($color: #fff, $alpha: 0.8);
  display: flex;
  align-items: center;
}

.hideShareButton {
  display: none;
}
