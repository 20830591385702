$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

$breakpoints: (
  'xs': $xs,
  'sm': $sm,
  'md': $md,
  'lg': $lg,
  'xl': $xl,
) !default;

:export {
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
}
