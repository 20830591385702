@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $base * 4;
    margin-bottom: $base * 2;
  }

  p {
    margin-bottom: $base * 2;
  }

  em strong {
    color: $secondary;
    font-style: normal;
    font-weight: inherit;
  }

  a {
    color: $primaryLight;

    &:hover {
      text-decoration: underline;
    }
  }

  u {
    text-decoration: none;
    color: $secondary;
    background-image: linear-gradient(
      to bottom,
      $secondary $base,
      transparent $base,
      transparent $base * 1.5,
      $secondary $base * 1.5,
      $secondary $base * 2.5,
      transparent $base * 2.5,
      transparent $base * 3,
      $secondary $base * 3,
      $secondary $base * 4,
      transparent $base * 4,
      transparent $base * 4.5,
      $secondary $base * 4.5,
      $secondary $base * 5.5
    );
    background-position: 0 1.25em;
    background-repeat: repeat-x;
    background-size: 2px 44px;
    padding-bottom: 2em;
    line-height: 1.5;

    @include mediaMax('md') {
      background-image: linear-gradient(
        to bottom,
        $secondary $base * 0.5,
        transparent $base * 0.5,
        transparent $base * 0.75,
        $secondary $base * 0.75,
        $secondary $base * 1.25,
        transparent $base * 1.25,
        transparent $base * 1.5,
        $secondary $base * 1.5,
        $secondary $base * 2,
        transparent $base * 2,
        transparent $base * 2.25,
        $secondary $base * 2.25,
        $secondary $base * 2.75
      );
      background-position: 0 1.1em;
      background-size: $base * 0.25 $base * 2.75;
      padding-bottom: 1.1em;
      line-height: 1.5;
    }
  }

  :first-child {
    margin-top: 0 !important;
  }
  :last-child {
    margin-bottom: 0 !important;
  }
}

.highlight {
  a {
    color: $orange;
  }
}

.textAlignCenter {
  text-align: center;
}
