@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.highlightsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $base;

  .chip {
    @include mediaMax('sm') {
      font-size: $body2FontSizeMobile;
    }
  }
}
