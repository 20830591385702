@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.titleRow {
  padding: 0 $base * 2;
  border-bottom: 3px solid $primary;
  margin-bottom: $base * 2;

  @include mediaMin('md') {
    padding: 0;
  }

  .titleRowText {
    font-weight: 500;
    text-transform: uppercase;
  }
}

.tableBody {
  :global(.MuiTableRow-root) {
    &:nth-child(odd) {
      background: $backgroundDefault;
    }
  }
  :global(.MuiTableCell-root) {
    padding: $base $base * 2;
    font-size: $body2FontSize;
    line-height: $body2LineHeight;
    border-bottom: none;
  }
}

.leftColumn {
  font-weight: 500;
}
