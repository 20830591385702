@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';

.left.left {
  margin-bottom: $base * 4;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.right.right {
  margin-bottom: $base * 4;

  @include mediaMax('md') {
    justify-content: left;
  }
}
