@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.boxContainer {
  border: solid 2px $primary;
  padding: $base * 3;
  background: white;
  height: 100%;

  @include mediaMax('lg') {
    margin-left: $base * 2;
  }
}
