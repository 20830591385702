@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/base/zIndexes.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
// this custom css for dialog used from MUI dialog.

.dialog {
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  &.open {
    z-index: $zIndex10;
  }
}

.container {
  overflow: hidden;
  position: fixed;
  height: 100%;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  transition:
    width 0.5s ease,
    height 0.5s ease;

  &.open {
    height: 100%;
    width: 100%;
    transition:
      width 0.5s ease,
      height 0.5s ease;
  }
}

.overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.open {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.content {
  background-color: $primaryContrastText;
  border-radius: 0;
  margin: $base * 4;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
  box-shadow:
    0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
