@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.collapseHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: $base * 2;

  :global(.MuiSvgIcon-root) {
    transition: transform $slowTransition;
  }
}

.isClosed {
  :global(.MuiSvgIcon-root) {
    transform: rotate(180deg);
  }
}

.title {
  display: flex;
  flex: 1;

  h6 {
    font-size: 14px !important;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.reverseDirection {
  flex-direction: row-reverse;
}

.defaultCursor {
  cursor: default;
}
