@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.shareLinkList {
  display: flex;
  flex-direction: column;
  background-color: $pageBackground;
  border-radius: $base;
  padding: calc($base * 1.5) 0 0;

  @include mediaMin('md') {
    padding: calc($base * 1.5) 0 0;
  }
}

.shareIconButton {
  height: 100%;

  & svg {
    width: calc($base * 3);
    height: calc($base * 3);
  }

  @include mediaMin('md') {
    & svg {
      width: calc($base * 4);
      height: calc($base * 4);
    }
  }
}

.shareLinkButton {
  & svg {
    color: $primary;
  }
}
