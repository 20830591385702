// CI colors: https://zeroheight.com/04abe329e/p/87ceb1-brand-colors
// Primary color-mix
$authenticGreen: #61d63a;
$deepGreen: #073328;

// Accent Colors (UI, CTA, Stopper)
$actionPetrol: #09e0ab;
$actionOrange: #ff630f;

// Secondary Colors (Background & UI)
$petrol: #083d30;
$lightPetrol: #0d6650;

// other
$greenGray: #cae0dc;
$greenWhite: #e8f4f2;
$gray: #d3dede;
$lightGray: #f0f0f0;

// --------------------------------------------------------------------

$primary: $deepGreen;
$primaryLight: $lightPetrol;
$primaryHighlight: #e8f4f2;
$secondary: $authenticGreen;

$orange: $actionOrange;
$greyLight: $lightGray;
$greenDark: $petrol;
$parisWhite: $greenGray;

$secondaryDark: darken($secondary, 5%);

$textPrimary: #303030;
$textSecondary: #00000060;
$textDisabled: #00000038;
$textInvertedPrimary: #ffffff;
$textInvertedSecondary: #ffffff60;

$border: #00000023;
$borderInverted: #ffffff23;
$overlayBackground: #00000050;
$overlayBackgroundInverted: #ffffff50;
$black: #000000;
$pageBackground: #ffffff;

$primaryContrastText: #fff;
$primaryContrastBackground: #ffffffd9;

$lightPetrol: #0d6650;

$secondaryLight: #ffd480;
$secondaryMain: #ffb826;
$secondaryContrastText: #fff;

$backgroundPrimary: $primary;
$backgroundSecondary: $secondary;
$backgroundDefault: #fafafa;
$backgroundPaper: #fff;

$actionActive: #757575;
$actionHover: #f5f5f5;
$actionSelected: #ebebeb;
$actionDisabled: #bdbdbd;
$actionDisabledBackground: #e0e0e0;
$actionFocus: #e0e0e0;

$inputBorderNeutral: rgba(0, 0, 0, 0.23);
$inputBorderHover: #212121;
$inputBorderFocus: $secondary;
$outlinedBorder: $inputBorderNeutral;

$divider: rgba(0, 0, 0, 0.12);

$shim: rgba(0, 0, 0, 0.5);

$black80: rgba(black, 80%);
$white70: rgba(white, 70%);

$error: #f44336;

:export {
  primary: $primary;
  secondary: $secondary;
  border: $border;
  borderInverted: $borderInverted;
  black: $black;
  primaryLight: $primaryLight;
  primaryHighlight: $primaryHighlight;
  orange: $orange;

  primaryContrastText: $primaryContrastText;
  secondaryLight: $secondaryLight;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondaryContrastText: $secondaryContrastText;

  pageBackground: $pageBackground;
  overlayBackground: $overlayBackground;
  overlayBackgroundInverted: $overlayBackgroundInverted;
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundDefault: $backgroundDefault;
  backgroundPaper: $backgroundPaper;

  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  textDisabled: $textDisabled;
  textInvertedPrimary: $textInvertedPrimary;
  textInvertedSecondary: $textInvertedSecondary;

  actionActive: $actionActive;
  actionHover: $actionHover;
  actionSelected: $actionSelected;
  actionDisabled: $actionDisabled;
  actionDisabledBackground: $actionDisabledBackground;
  actionFocus: $actionFocus;
  inputBorderNeutral: $inputBorderNeutral;
  inputBorderHover: $inputBorderHover;
  inputBorderFocus: $inputBorderFocus;
  outlinedBorder: $outlinedBorder;
  divider: $divider;
  shim: $shim;
  error: $error;

  petrol: $petrol;
  lightPetrol: $lightPetrol;
  greyLight: $greyLight;
}
