@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.cluster {
  display: flex;
  flex-direction: row;
  gap: $base;
  align-items: center;
  position: relative;
  color: $primary;
  text-wrap: nowrap;
}

.serviceNumberReveal {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: $body1FontSize;
  color: $primary;

  &:hover {
    color: black;
  }
}

.slideContainer {
  position: absolute;
  left: 0;
  // To align with the rest of the header, we use the material design input padding here
  top: -10px;
  width: max-content;
}

.overlay {
  background: $secondary;
  padding-inline: $base * 0.5;
  padding-block: $base;
}

.serviceNumber {
  color: $primary;
  font-size: $body2FontSize;

  &:hover {
    color: black;
  }

  & > p {
    margin: 0;
  }
}

.close {
  margin-left: $base * 2;
  cursor: pointer;
  color: $primary;

  &:hover {
    color: black;
  }
}
