@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.wrapper {
  background: #fff;
  border: solid 1px $outlinedBorder;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: $base * 6 0;
  margin-bottom: $base * 6;

  @include mediaMax('lg') {
    padding: $base * 2 0;
    margin-bottom: $base * 2;
  }
}

.notAvailable {
  margin-top: $base * 5;
  margin-bottom: $base * 11;

  img {
    width: 100%;
    height: auto;
  }

  h2 {
    margin-bottom: $base * 2;
  }

  @include mediaMax('lg') {
    margin: $base * 3 0;
  }
}

.progress {
  margin: $base * 6 0;

  @include mediaMax('lg') {
    margin: $base * 2;
  }
}
