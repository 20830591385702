@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  display: flex;
  align-items: center;
  margin-right: $base * 2;
}

.icon {
  display: flex;
  margin-right: $base;
  font-size: $base * 3;

  & svg {
    color: $textDisabled;
  }
}
