@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.marker {
  margin-left: calc(#{$base} / 2);
  color: $textPrimary;
  font-size: $body2FontSize;
}

.featureAvailable {
  color: $authenticGreen;
}

.featureMissing {
  color: $textDisabled;
}
