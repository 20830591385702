@import '@renderer-ui-library/mui/base/constants.module.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :global(.MuiTextField-root) {
    width: 100%;
  }
}

.separator {
  width: $base;
  margin: 0 $base;
}

.errorMessage {
  margin-top: $base;
}
