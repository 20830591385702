.item {
  display: block;

  &.unwrapped {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  width: 36px;
  height: 36px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 8px;
  margin-top: 4px;
}
