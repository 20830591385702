@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.highlightWrapper {
  display: flex;
  justify-content: flex-start;
  padding: $base 0 $base * 2;
  flex-wrap: wrap;

  & p {
    margin: 0;
  }
}

.highlight {
  margin-right: calc($base * 3);
  text-align: left;

  @include mediaMin('md') {
    margin-right: $base;
  }
}

.checkBoxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: $white70;
  width: 100%;
  z-index: 1;

  label {
    width: 100%;
    padding: 0 $base * 2;
    margin: 0 !important;
  }
}
