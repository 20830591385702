@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.buttonGroup {
  display: flex;
  flex-direction: column;

  @include mediaMin('md') {
    flex-direction: row;
  }
}

.center {
  justify-content: center;
}

.button {
  @include mediaMax('md') {
    flex-grow: 1;
  }

  & + & {
    margin-top: $base * 2;
  }
}

@include mediaMin('md') {
  .button {
    & + & {
      margin-top: 0;
      margin-left: $base * 2;
    }
  }
}
