@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.imageContainer {
  position: relative;
  width: 33.333%;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: $base * 2;
  background: #fff;
  border-right: solid 1px $inputBorderNeutral;

  @include mediaMax('md') {
    text-align: left;

    button {
      min-width: auto !important;
    }
  }

  @include mediaMax('sm') {
    width: $compareColWidthPx !important;
    min-width: $compareColWidthPx !important;
  }
}

.mediaContainer {
  position: relative;
  height: auto;
}

.icon {
  position: absolute;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  background: $black80;
  padding: 4px;
  top: $base;
  right: $base;
  z-index: 1; // presence of z-index puts it above the images slider

  @include mediaMax('md') {
    top: calc($base / 2);
    right: calc($base / 2);
  }
}

$cellPadding: $base * 2;

.buttonWrapper,
.headlineWrapper {
  padding: $cellPadding;
}

.buttonWrapper {
  text-align: left;

  @include mediaMax('lg') {
    button {
      min-width: auto !important;
    }
  }
}

.headlineWrapper {
  text-align: left;

  a:hover {
    color: $primary;
    text-shadow: 0 0 1px $primary;
  }
}
