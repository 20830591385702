@import '@renderer-ui-library/mui/base/fontSizes.module.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.headline {
  & h4 {
    font-size: $h5FontSize;
    margin-bottom: $base * 2;
  }
}
.subline {
  & p {
    font-size: $body1FontSizeMobile;

    @include mediaMin('md') {
      font-size: $body1FontSize;
    }
  }
}
